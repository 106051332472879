<template>
  <div>
    <v-row>
      <v-col align-self="center" class="d-flex justify-center">
        <v-card style="border-radius: 10px" flat max-width="100%" min-width="50%">
          <v-card-title primary-title class='primary white--text py-3 px-0'>
            <div class="pl-5" style="word-break: break-word">Informações Básicas</div>
          </v-card-title>
          <v-card-text class="pa-0 px-2">
            <main-data ></main-data>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'OneUser',
  components: {
    MainData: () => import('./register/new/MainData.vue'),
  },
  data() {
    return {
      tabs: null,
      loading_user: false,
      loading_department: false,
      saving: false,
      // loading_associations: false,
      // loading_contacts: false,
      // loading_bank_accounts: false,
    }
  },
  mounted () {
    this.getData()
    // this.showOneUser(this.$route.params.id).then( async (r)=>{
    //   try {
    //     // await this.getAssociations(this.$route.params.id)
    //     // await this.getAddress(this.$route.params.id)
    //     // await this.getContacts(this.$route.params.id)
    //     this.loading_user = false
    //   } catch (error) {
    //     throw new Error(error)
    //   }
    // }).catch(err=>{
    //   console.log({err});
    // })
  },
  beforeDestroy() {
    //this.clearOneUser()
  },
  methods: {
    getData(){
      this.loading_user = true
      setTimeout(() => {
        this.loading_user = false
      }, 500);
    },
    ...mapActions({
      showOneUser: 'User/showOneUser',
      //clearOneUser: 'User/clearOneUser',
    }),
    ...mapMutations({
      //setAssociations: 'User/setAssociations',
      //setAddress: 'User/setUserAddress',
      // setContacts: 'User/setUserContacts',
      // setBankAccounts: 'User/setUserBankAccounts',
    })
  },
  computed: {
    ...mapState({
      one_user: state => state.User.oneUser,
      user: state => state.User.user
    })
  },
}
</script>

<style lang="scss" scoped>

</style>
